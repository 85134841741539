/** @jsx jsx */
import {jsx} from '@emotion/react';
import {IAutoCompleteFilter, IFilterFieldType} from '../../filterTypes';
import {useMountedRef} from '../../hooks/useMountedRef';
import {useNextFilter, useSetNextFilter} from '../../hooks/nextFilter';
import {useCallback, useState} from 'react';
import {Def} from '../../utils/types';
import {AutoComplete, AutoCompleteProps} from 'primereact/autocomplete';
import {paddingCss, widthCss} from './styles';
import {FilterActions} from '../FilterActions';
import { ngsiSanitize } from '../../utils/ngsiSanitize';

export function AutoCompleteFilter<D extends IAutoCompleteFilter, V extends IFilterFieldType<D>>({
  field,
  description
}: {
  field: string;
  description: D;
}) {
  const mountedRef = useMountedRef();
  const {searchOptions, placeholder, minLength} = description;
  const nextFilter = useNextFilter();
  const setNextFilter = useSetNextFilter();
  const value = nextFilter[field] as V;

  const [suggestions, setSuggestions] = useState<string[]>([]);
  const completeMethod = useCallback<(e: {query: string}) => void>(
    ({query}) => {
      searchOptions(query)
        .then((res) => {
          if (mountedRef.current) {
            setSuggestions(res);
          }
        })
        .catch(console.error);
    },
    [searchOptions, mountedRef]
  );
  const onChange = useCallback<Def<AutoCompleteProps['onChange']>>(
    (e) => {
      const escapeValue = ngsiSanitize(String(e.value))
      setNextFilter((prev) => ({...prev, [field]: escapeValue}));
    },
    [field, setNextFilter]
  );
  return (
    <div css={[paddingCss, widthCss]}>
      <div className={'p-fluid p-input-filled'}>
        <AutoComplete
          dropdownMode={'current'}
          placeholder={placeholder}
          value={value}
          suggestions={suggestions}
          minLength={minLength}
          completeMethod={completeMethod}
          onChange={onChange}
        />
      </div>
      <FilterActions field={field} />
    </div>
  );
}
