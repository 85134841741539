import {
  createContext,
  createElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  useColumns,
  useDataSource,
  useFilter,
  useFilterDescription,
  usePage,
  usePreferencesDefaults,
  useSetPage
} from './tableState';
import {
  isArray,
  isFunction,
  isShape,
  isString,
  isUndefined,
  or
} from '../utils/basicValidators';
import { useMountedRef } from './useMountedRef';
import { IDataSource, IDataSourceResult, INgsiDataSource } from '../ITableProps';
import { IFilterDescription } from '../filterTypes';
import { createComposeQ } from '../filterCompose';
import { ngsiSanitize } from '../utils/ngsiSanitize';
import { useApiRepository } from './useApiRepository';
import { ISearchQueryPayload } from '@netvision/lib-api-repo';
import { createComposeDefaultQ } from '../utils/composeDefaultQ';

export type IDataContext<T extends {}> = [
  /** loading */
  boolean,
  /** result */
  IDataSourceResult<T>,
  /** forceLoad */
  () => void
];

const DataContext = createContext<IDataContext<any>>(null!);

export const useData = <T extends {}>(): IDataContext<T> => {
  return useContext(DataContext);
};

const isNgsiDataSource = isShape({
  type: or(isString, isArray(isString)),
  onLoad: or(isUndefined, isFunction)
});

export function DataProvider<T extends {}, K extends string, FK extends K, FD extends IFilterDescription<FK>>({
  children
}: {
  children?: ReactNode;
}) {
  const dataSource = useDataSource();
  if (isNgsiDataSource(dataSource)) {
    return createElement(NgsiDataProvider, {dataSource, children});
  } else if (isFunction(dataSource)) {
    return createElement(CustomDataProvider, {dataSource, children});
  }
  return null;
}

function NgsiDataProvider<T extends {}, K extends string, FK extends K, FD extends IFilterDescription<FK>>({
  dataSource,
  children
}: {
  dataSource: INgsiDataSource<T, K>;
  children?: ReactNode;
}) {
  const columns = useColumns();
  const {first, sortOrder, sortField, rows, rowsPerPage, invariableSortField, invariableFilterQuery} = usePage<K>();
  const memoPage = useMemo(
    () => ({first, sortOrder, sortField, rows, rowsPerPage, invariableSortField, invariableFilterQuery}),
    [first, sortOrder, sortField, rows, rowsPerPage, invariableSortField, invariableFilterQuery]
  );
  const { api } = useApiRepository();
  const filter = useFilter<FK, FD>();
  const filterDescription = useFilterDescription<FK>();
  const preferencesDefaults = usePreferencesDefaults();
  const mountedRef = useMountedRef();
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(loading);
  loadingRef.current = loading;
  const [result, setResult] = useState<IDataSourceResult<T>>(() => ({total: 0, data: [], error: ''}));
  const composeQ = useMemo(() => createComposeQ(filterDescription), [filterDescription]);
  const composeDefaultQ = useMemo(() => createComposeDefaultQ(invariableFilterQuery), [invariableFilterQuery]);
  const attrs = useMemo(() => {
    const set = new Set();
    columns.forEach((c) => set.add(c.field.split('.')[0]));
    dataSource.extraAttrs?.forEach((attr) => set.add(attr));
    return [...set].join(',');
  }, [columns, dataSource]);
  const id = useMemo(() => {
    const entityId = (filter as unknown as {id: unknown}).id;
    return Array.isArray(entityId) && entityId.length > 0 ? entityId.map((v) => ngsiSanitize(v)).join(',') : undefined;
  }, [filter]);
  const q: ISearchQueryPayload[] | undefined = useMemo(() => {
    const clean = { ...filter };
    // @ts-ignore
    delete clean['id'];
    // @ts-ignore
    delete clean['type'];
    return composeDefaultQ(composeQ(clean))
  }, [composeDefaultQ, composeQ, filter]);

  const createOrderByParam = () => {
    let queryParam = '';
    if (memoPage.sortField === null) {
      if (preferencesDefaults?.groupingColumnFields?.length) {
        queryParam += preferencesDefaults?.groupingColumnFields?.join(',');
      }
    } else {
      queryParam += (memoPage.sortOrder === -1 ? '!' : '') + memoPage.sortField;
    }
    if (invariableSortField) {
      queryParam += `,${invariableSortField}`;
    }
    return queryParam.length ? queryParam : undefined;
  };
  const orderBy = useMemo(createOrderByParam, [invariableSortField, memoPage, preferencesDefaults]);
  const offset = memoPage.first;
  const limit = memoPage.rows;
  const type = useMemo(
    () => (Array.isArray(dataSource.type) ? dataSource.type.join(',') : dataSource.type),
    [dataSource]
  );
  const onLoadRef = useRef(dataSource.onLoad);
  onLoadRef.current = dataSource.onLoad;
  const onLoadStartRef = useRef(dataSource.onLoadStart);
  onLoadStartRef.current = dataSource.onLoadStart;
  const forceLoad = useCallback(() => {
    if (!loadingRef.current) {
      loadPageRef.current();
    }
  }, []);
  const setPage = useSetPage<K>();
  const setPageRef = useRef(setPage);
  setPageRef.current = setPage;
  const queryListener = (q?: ISearchQueryPayload[]) => q?.map(({ value }) => value).join()
  const loadPage = useCallback(() => {
    const isOutdated = () => loadPage !== loadPageRef.current;
    if (mountedRef.current) {
      const onLoadStart = onLoadStartRef.current;
      if (isFunction(onLoadStart)) {
        onLoadStart();
      }
      setLoading(true);
      api.getEntitiesList<T>({
        limiter: {
          id,
          type,
          orderBy,
          offset,
          limit
        },
        filter: {
          q,
          attrs,
          count: true
        }
      })
        .then(({results, count}) => {
          if (mountedRef.current && !isOutdated() && typeof count === 'number') {
            const result: SetStateAction<IDataSourceResult<T>> = {
              error: '',
              total: count,
              data: results
            };
            const onLoad = onLoadRef.current;
            if (isFunction(onLoad)) {
              // @ts-ignore
              onLoad({page: memoPage, result, forceLoad, setPage: (...args) => setPageRef.current(...args)});
            }
            setLoading(false);
            setResult(result);
          }
        })
        .catch((e: Error) => {
          console.error(e)
          if (mountedRef.current && !isOutdated()) {
            setLoading(false);
            setResult({
              error: 'Network error',
              total: 0,
              data: []
            });
          }
        });
    }
  }, [memoPage, attrs, id, type, orderBy, offset, limit, mountedRef, forceLoad, queryListener(q)]);
  const loadPageRef = useRef(loadPage);
  loadPageRef.current = loadPage;
  useEffect(() => {
    loadPage();
  }, [loadPage]);
  return createElement(
    DataContext.Provider,
    {value: useMemo<IDataContext<T>>(() => [loading, result, forceLoad], [loading, result, forceLoad])},
    children
  );
}

function CustomDataProvider<T extends {}, K extends string, FK extends K, FD extends IFilterDescription<FK>>({
  dataSource,
  children
}: {
  dataSource: IDataSource<T, K, FK, FD>;
  children?: ReactNode;
}) {
  const page = usePage<K>();
  const filter = useFilter<FK, FD>();
  const mountedRef = useMountedRef();
  const [loading, setLoading] = useState(true);
  const loadingRef = useRef(loading);
  loadingRef.current = loading;
  const [result, setResult] = useState<IDataSourceResult<T>>(() => ({total: 0, data: [], error: ''}));
  const forceLoad = useCallback(() => {
    if (!loadingRef.current) {
      loadPageRef.current();
    }
  }, []);
  const loadPage = useCallback(() => {
    const isOutdated = () => loadPage !== loadPageRef.current;
    if (mountedRef.current) {
      setLoading(true);
      dataSource(page, filter)
        .then((result) => {
          if (mountedRef.current && !isOutdated()) {
            setLoading(false);
            setResult(result);
          }
        })
        .catch((e) => {
          console.error(e);
          if (mountedRef.current && !isOutdated()) {
            setLoading(false);
            setResult({
              error: 'Network error',
              total: 0,
              data: []
            });
          }
        });
    }
  }, [dataSource, filter, page, mountedRef]);
  const loadPageRef = useRef(loadPage);
  loadPageRef.current = loadPage;
  useEffect(() => {
    loadPage();
  }, [loadPage]);
  return createElement(
    DataContext.Provider,
    {value: useMemo<IDataContext<T>>(() => [loading, result, forceLoad], [loading, result, forceLoad])},
    children
  );
}
